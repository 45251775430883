import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { StyledButton } from '../styles'
import { Link } from 'gatsby'

const StyledLanding = styled.section`
  height: 65rem;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  overflow: hidden;
  @media (max-width: 600px) {
    height: 55rem;
  }
  .jumbotron {
    position: relative;
    min-height: 60rem;
    filter: brightness(0.7);
    .gatsby-image-wrapper {
      width: 1920px;
    }
  }
  .content {
    position: absolute;
    height: 100vh;
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    top: 12rem;
    text-align: center;
    text-transform: uppercase;
    overflow: none;
    .buttons {
      display: flex;
      justify-content: center;
      width: 100%;
      @media (max-width: 600px) {
        flex-direction: column;
      }
    }
    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      strong {
        font-family: 'Gotham Black';
        font-size: 1.267rem;
        color: ${({ theme }) => theme.backgroundcolor};
      }

      .sliderLogo {
        max-width: 127px;
        margin: auto;
      }
      h1 {
        margin-top: 3rem;
        color: ${({ theme }) => theme.backgroundcolor};
      }
      p {
        color: ${({ theme }) => theme.backgroundcolor};
      }
    }
  }
`

const Landing = () => {
  const landingData = useStaticQuery(graphql`
    query largeImage {
      file(name: { eq: "slider-1" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            base64
            tracedSVG
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
            originalImg
            originalName
            presentationWidth
            presentationHeight
          }
        }
      }
      wpgraphql {
        pageBy(id: "cGFnZToxNg==") {
          tournament_iteration {
            tournamentIteration
          }
          tournament_location {
            tournamentLocationName
          }
          tournament_dates {
            tournamentStartingDate
            registrationStartingDate
          }
        }
      }
    }
  `)

  const {
    file: {
      childImageSharp: { fluid },
    },
    wpgraphql: {
      pageBy: {
        tournament_iteration: { tournamentIteration },
        tournament_location: { tournamentLocationName },
        tournament_dates: { tournamentStartingDate, registrationStartingDate },
      },
    },
  } = landingData

  return (
    <StyledLanding>
      <div className="content">
        <div className="text">
          <h1 style={{ marginBottom: '1rem' }}>
            Circle K Charity <br />
            Golf Tournament!
          </h1>
          <p>
            {tournamentIteration} - {tournamentLocationName} <br />
            {tournamentStartingDate}
          </p>
          <strong>Registration Opens {registrationStartingDate}</strong>
        </div>
        <div className="buttons">
          <Link to="/sponsorship">
            <StyledButton>Sponsorship</StyledButton>
          </Link>
          <Link to="/registration">
            <StyledButton>Registration</StyledButton>
          </Link>
        </div>
      </div>

      <div className="jumbotron">
        <Img fluid={fluid} />
      </div>
    </StyledLanding>
  )
}

export default Landing

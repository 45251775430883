import React from 'react'
import Layout from '../layouts/siteLayout'
import Landing from '../components/landing'
import Countdown from '../components/countDown'

const LandingPage = () => {
  return (
    <Layout>
      <Landing />
      <Countdown />
    </Layout>
  )
}

export default LandingPage

import React, { useState, useEffect } from 'react'

const CountDownTimer = (props) => {
  const [time, setTime] = useState({})

  useEffect(() => {
    setInterval(() => {
      const date = calculateCountdown(props.date)
      setTime(date)
    }, 1000)
  }, [props.date])

  const calculateCountdown = (endDate) => {
    let difference = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000

    // clear countdown when date is reached
    if (difference <= 0) return false

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    }

    // Calculate time difference between now and expected date
    if (difference >= 365.25 * 86500) {
      timeLeft.years = Math.floor(difference / (365.25 * 86400))
      difference -= timeLeft.years * 365.25 * 86400
    }
    if (difference >= 86400) {
      // 24 * 60 * 60
      timeLeft.days = Math.floor(difference / 86400)
      difference -= timeLeft.days * 86400
    }
    if (difference >= 3600) {
      // 60 * 60
      timeLeft.hours = Math.floor(difference / 3600)
      difference -= timeLeft.hours * 3600
    }
    if (difference >= 60) {
      timeLeft.minutes = Math.floor(difference / 60)
      difference -= timeLeft.minutes * 60
    }
    timeLeft.seconds = difference

    return timeLeft
  }

  const addLeadingZeros = (value) => {
    value = String(value)
    while (value.length < 2) {
      value = '0' + value
    }
    return value
  }

  const countDown = time

  return (
    <>
      <div className="countdownItem">
        <strong>{addLeadingZeros(countDown.days)}</strong>
        <span>{countDown.days === 1 ? 'Day' : 'Days'}</span>
      </div>
      <div className="seperator">:</div>
      <div className="countdownItem">
        <strong>{addLeadingZeros(countDown.hours)}</strong>
        <span>{countDown.days === 1 ? 'Hour' : 'Hours'}</span>
      </div>
      <div className="seperator">:</div>
      <div className="countdownItem">
        <strong>{addLeadingZeros(countDown.minutes)}</strong>
        <span>{countDown.days === 1 ? 'Minute' : 'Minutes'}</span>
      </div>
      <div className="seperator">:</div>
      <div className="countdownItem">
        <strong>{addLeadingZeros(countDown.seconds)}</strong>
        <span>{countDown.days === 1 ? 'Second' : 'Seconds'}</span>
      </div>
    </>
  )
}

export default CountDownTimer

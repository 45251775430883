import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { StyledSection, StyledButtonBlack } from '../styles'
import CountDownTimer from '../components/countDownTimer'
import { useStaticQuery, graphql } from 'gatsby'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 4rem 0;

  .text {
    flex: 1;
    width: 100%;

    h3 {
      font-size: 1.467rem;
    }
  }

  .timer {
    flex: 2;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: flex-start;

    .countdownItem {
      display: flex;
      flex-direction: column;

      strong {
        background-color: ${({ theme }) => theme.subprimary};
        color: ${({ theme }) => theme.primary};
        font-size: 2rem;
        padding: 0.1rem 1.4rem;
        border-radius: 0.5rem;
      }

      span {
        text-align: center;
      }
    }

    .seperator {
      display: flex;
      transform: translateY(-0.7rem);
      font-size: 2rem;
      padding: 0.8rem;
      border-radius: 0.5rem;
    }
  }

  .button {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 1120px) {
    flex-direction: column;
    text-align: center;

    .button {
      justify-content: center;
    }
  }

  @media (max-width: 533px) {
    .timer {
      .seperator {
        padding: 0.8rem 0.2rem;
      }

      .countdownItem {
        strong {
          padding: 0.1rem 0.7rem;
        }
      }
    }
  }
`

const CountDown = () => {
  const countdownData = useStaticQuery(graphql`
    query countdownTimerQuery {
      wpgraphql {
        pageBy(id: "cGFnZToxNg==") {
          tournament_dates {
            tournamentStartingDateCountdown
          }
          tournament_location {
            tournamentLocationName
          }
        }
      }
    }
  `)

  const {
    wpgraphql: {
      pageBy: {
        tournament_dates: { tournamentStartingDateCountdown },
        tournament_location: { tournamentLocationName },
      },
    },
  } = countdownData

  const formattedDate = tournamentStartingDateCountdown.replace(/\//g, '-') + 'T00:00:00'

  return (
    <StyledSection>
      <StyledContainer>
        <div className="text">
          <p>Next Tournament</p>
          <h3>{tournamentLocationName}</h3>
        </div>
        <div className="timer">
          <CountDownTimer date={formattedDate} />
        </div>
        <div className="button">
          <Link to="/directions">
            <StyledButtonBlack>Directions</StyledButtonBlack>
          </Link>
        </div>
      </StyledContainer>
    </StyledSection>
  )
}

export default CountDown
